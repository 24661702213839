@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}


:root{
  --gray-light:#F4F4F4;
  --blue-light:#52baf2;
  --dark-blue:#007DBC;
  --gray-dark:#868686;
  --gray:#BEBEBD;
  --gray-bg:#DEDEDE;
}

html{
  background-color: var(--gray-light);
  width: 100vw;
  overflow-x: hidden;
}

.passwordIcon {
  top: 50%;
  right: 0px;
  transform: translateY(-50%)
}

.passwordIconCenter {
  top: 68%;
  right: 0px;
  transform: translateY(-50%)
}

.active_nav_item{
  background-color: #7fc6ed;
}

.active_nav_item::before{
  content: "";
  height: 100%;
  width: 7px;
  background-color:var(--dark-blue);
  position: absolute;
  top: 0;
  left: 0;
}

.inactive_nav_item:hover{
  background-color: #7bc6ee71;
}
.bgModal{
  background-color: #3939395a;
}
.linearBlue{
  background: linear-gradient(to bottom,#9BD6E8,40%,#ffffff)
}

/* .dashboardTableWrapper {
  width: 100%;
  overflow-x: auto !important;
} */

.profile-dropdown{
  position: absolute;
  top: 110%;
  left: -10%;

}
